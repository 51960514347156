<template>
  <div>
    <div class="ScanToBIM_page">
      <div class="banner_wrap">
        <p class="title fadeInLeft slow animated wow">SCAN TO BIM</p>
        <p class="des fadeInRight slow animated wow">UCL360 S深化设计助手</p>
      </div>
   
      <div class="des_wrap">
        <div class="des_content">
          <div class="item">
            <img class="left fadeInLeft slow animated wow" src="@/assets/ScanToBIM_1.png" alt="" />
            <div class="right fadeInRight slow animated wow">
              <div class="title">Scan To Revit</div>
              <p class="name">逆向设计</p>
              <p class="des" style="margin-bottom: 0.13rem">
                支持根据点云投影生成的户型图自动重建Revit模型，以便在Revit中进一步实现逆向设计、工程算量等任务。
              </p>
              <p class="name">数字化监理</p>
              <p class="des">
                支持基于Revit模型的测量任务规划、扫描点云与模型的坐标匹配与对比、测量数据自动上墙与自定义数据报表。
              </p>
            </div>
          </div>

          <div class="item">
            <div class="right fadeInLeft slow animated wow">
              <div class="title">Scan To CAD</div>
              <p class="name">2D图纸重建摸工具</p>
              <p class="des" style="margin-bottom: 0.13rem">
                支持在以PDF格式导出的CAD图纸上，增加BIM模型信息和其他各类结构化与非结构化数据，以便于构建各类需要对CAD图纸进行加工的数字化平台。
              </p>
              <p class="name">数字化监理</p>
              <p class="des">
                支持基于CAD图纸的测量任务规划、扫描点云与2D图纸的坐标匹配与对比、测量数据自动上墙与自定义数据报表。
              </p>
            </div>
            <img class="left fadeInRight slow animated wow" src="@/assets/ScanToBIM_2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from "wowjs"
export default {
  name: "UCL360",
  data() {
    return {
      isMobile: false,
    };
  },
  components: {},
  created() {},
  mounted() {
     this.$nextTick(() => {
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
  },
  methods: {},
};
</script>

<style lang="less">
.ScanToBIM_page {
  background-color: #EDF1F7;
    overflow: hidden;
  .banner_wrap {
    background-image: url(../assets/ScanToBIM_bg.png);
    background-size: cover;
    width: 10rem;
    height: 2.88rem;
    padding-top: 0.96rem;
    .title {
      margin-bottom: 0.1rem;
      color: #fcfcfc;
      font-size: 0.32rem;

  position: absolute;
    left: 23.4%;
    top: 18.3%;
    }
    .des {
      color: #e6e6e6;
      font-size: 0.17rem;

      position: absolute;
    left: 24.1%;
    top: 24.2%;
    }
  }

  .des_wrap {
    padding: 0.32rem 1.05rem;
    
    .des_content {
      width: 100%;
      .item {
        padding: 0.19rem 0.34rem;
        background: #fff;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.29rem;
        &:last-child{
             margin-bottom:0;
          }
      }
      .left {
        width: 3.46rem;
      }
      .right {
        width: 3.4rem;
        text-align: left;
        .title {
          border-left: 0.02rem solid #02aeec;
          height: 0.17rem;
          margin-bottom: 0.08rem;
          margin-top: 0.25rem;
          line-height: 0.17rem;
          padding-left: 0.06rem;
          color: #02aeec;
          font-weight: 600;
          font-size: 0.15rem;
        }
        .name {
          margin-bottom: 0.09rem;
          color: #4d4d4d;
          font-weight: 600;
          font-size: 0.1rem;
        }
        .des {
          line-height: 0.16rem;
          color: #808080;
          font-size: 0.084rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .ScanToBIM_page {
    .breadcrumb_wrap{
      display: none;
    }
    .banner_wrap {
      background-image: url(../assets/ScanToBIM_bg_m.png);
      background-size: cover;
      width: 10rem;
      height: 16.79rem;
      padding-top: 1.4rem;
      .title {
        margin-bottom: 0.28rem;
        color: #fcfcfc;
        

font-size: 0.89rem;
    position: inherit;
      }
      .des {
        color: #e6e6e6;
        display: inline-block;

font-size: 0.52rem;
    position: inherit;
      }
    }

    .des_wrap {
      padding: 0.61rem 0.43rem ;
      background: #fff;
      .des_content {
        width: 100%;
        .item {
          padding: 0.55rem 0.34rem;
          background: #EDF1F7;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.29rem;
          &:last-child{
            flex-wrap: wrap-reverse;
          }
        }
        .left {
          width: 100%;
        }
        .right {
          width:100%;
          text-align: left;
          .title {
            border-left: 0.07rem solid #02aeec;
            
height: 0.63rem;
            margin-bottom: 0.16rem;
            line-height: 0.63rem;
            padding-left: 0.06rem;
            color: #02aeec;
            font-weight: 600;
            
font-size: 0.43rem;
          }
          .name {
            margin-bottom: 0.24rem;
            color: #4d4d4d;
            font-weight: 600;
font-size: 0.37rem;
          }
          .des {
            
line-height: 0.48rem;
            color: #808080;
            
font-size: 0.32rem;
          }
        }
      }
    }
  }
}
</style>
